import Vue from 'vue'
import VueRouter from 'vue-router'
import {all} from "axios";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/largeScreen'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/index')
    },
    {
        path: '/largeScreen',
        name: 'LargeScreen',
        component: () => import('../views/large-screen/index.vue')
    },
    {
        path: '/farm',
        name: 'Farm',
        component: () => import('../views/farm/index.vue')
    },
    {
        path: '/404',
        name: 'ExceptionPage404',
        component: () => import('../views/error-page/404')
    },
    {
        path: '*',
        name: 'ExceptionPage404',
        component: () => import('../views/error-page/404')
    }
]
const router = new VueRouter({
    routes
})

const allowList = ['Login', 'ExceptionPage404']
// 路由拦截守卫
router.beforeEach((to, from, next) => {
    const isLogin = sessionStorage.getItem('userName')
    if (isLogin) {
        // 登录状态
        next()
    } else {
        // 未登录状态
       if (allowList.includes(to.name)) {
           next()
       } else{
           next('/login')
       }
    }
})

export default router